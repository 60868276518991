<template>
  <v-content class="px-3">
    <Content :item="item" v-if="isCoupon === true"></Content>
    <p align="center" class="pt-1" v-else-if="isCoupon === false">このクーポンは利用できません</p>
  </v-content>
</template>

<script>
import Content from '@/views/coupon/content.vue'
export default {
  data: () => ({
    item: [],
    badgeParams: {},
    isCoupon: null
  }),
  components: {
    Content
  },
  watch: {
    item: {
      deep: true,
      handler: function () {
        if (this.item.title) {
          this.isCoupon = true;
        } else {
          this.isCoupon = false;
        }
      }
    }
  },
  methods: {
  },
  async created () {

    // GET パラメータを取得
    this.getUrlParam()

    //createdで、localstrageから全ての保存情報を取得しinitに格納
    this.init = this.storageGet('*')

    const params = { params:{
      'sid': this.init.sid,
      'service_cd': this.init.service_cd,
      'device_uid': this.init.device_uid,
      'device_id': this.init.device_id,
      'os': this.init.os,
      'key': this.init.key,
      'id': this.init.id,
      'preview':  this.getUrlGetParam('preview'),
      'push_preview':  this.getUrlGetParam('push_preview'),
    }}
    try{
      // バッジ数更新のため、クーポン取得
      const res = await this.apiCall('/user/coupon', params, 'get');
      if (!res) return false;
      this.$set(this, 'item', res);

      // プレビューは後続処理不要
      if (this.getUrlGetParam('preview')) {
        return;
      }

      // push既読処理、プッシュ通知タップの時、直接このページを表示するため、ここで既読にするしかない
      const notification_req = {
        'device_id': this.init.device_id,
        'contents_type' : 2, // クーポン
        'contents_id' : this.init.id, // クーポンID
        'status' : 2, // 既読
      }
      await this.apiCall('/user/notifications/status', notification_req, 'post')

      await this.callUserInitAndSendBadgeConditionToNative()
    } catch(e) {
      this.callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
    }
  }
}
</script>

<style scoped>
/* vue css override */

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 10px;
  margin-bottom: 40px;
}

/* original css */
@import url('https://fonts.googleapis.com/css?family=Oswald&display=swap');

.detailhead {
  text-align: center;
  font-size: 11px;
  margin: 0 0 10px;
}

.topbar {
  height: 15px;
  margin-bottom: 15px;
}

.vdbar {
  width: 94%;
  margin: 16px auto 0;
}

.vdbar2 {
  width: 94%;
  margin: 0 auto 16px;
}

.titlebottombar {
  height: 1px;
  width: 30%;
  margin: 0 auto;
}

.chips {
  padding: 3px 10px;
  color: #fff;
  background-color: #EB762F;
  font-size: 10px;
  transform: scale(0.9);
  transform-origin: left center;
  border-radius: 20px;
}

.list {
  padding: 16px;
}

.list .col {
  padding: 8px;
}

.list h2 {
  font-weight: bold;
  font-size: 11px;
  line-height: 20px;
  color: #9d9d9d;
}

.list p {
  font-size: 14px;
  line-height: 20px;
  color: #333;
  margin-bottom: 0;
  font-weight: bold;
}

.list p.notes {
  font-size: 11px;
  font-weight: normal;
  line-height: 16px;
}

.coupon_contents {
  padding: 16px;
}

.coupondates p {
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  margin-bottom: 5px;
  color: #9d9d9d;
  transform: scale(0.9);
  transform-origin: left center;
}

.flgtext {
  font-size: 16px;
  font-weight: bold;
  padding-top: 5px;
}

.usage {
  font-size: 16px;
  color: #333;
  line-height: 24px;
  text-align: center;
  padding-bottom: 10px;
}
.usage span.big {
  font-size: 40px;
  font-weight: bold;
  font-family: 'Oswald', sans-serif;
}
.bold {
  font-weight: bold;
}
</style>
