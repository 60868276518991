<template>
  <div class="mx-auto mt-3 mb-10 coupon-detail" max-width="400">
    <v-row class="pt-3 mb-2 mx-4" style="font-size: 0.625rem;">
      <v-col cols="2" class="pa-0 pr-3 text-left font-weight-bold">対象店舗</v-col>
      <v-col cols="10" class="pa-0 text-left">{{item.shop_names}}</v-col>
    </v-row>

    <v-card class="mx-auto" max-width="400" outlined>
      <v-row justify="center" align="center" v-if="item.distribution_name && item.distribution_type == 2">
        <div class="chips">
          {{item.distribution_name}}
        </div>
      </v-row>
      <v-row justify="center" align="center" v-if="item.distribution_name && item.distribution_type == 3">
        <div class="chips birth">
          {{item.distribution_name}}
        </div>
      </v-row>

      <v-card-title class="coupon-title nl2br" style="font-size: 1rem">{{item.title}}</v-card-title>
      <v-card-title class="coupon-title nl2br" style="font-size: 1rem">{{item.discount_text}}</v-card-title>

      <div class="coupon_contents" v-if="item.lottery_flg === 0 || item.scratch_flg == 1" v-html="_removeTargetBlank(item.comment)"></div>
      <div class="coupon_contents" v-html="item.lottery_comment" v-else></div>

      <v-row class="my-0 mx-4" style="font-size: 0.75rem; border-top:1px solid #E6E6E6">
        <v-col cols="3" class="px-0 font-weight-bold">
          種別
        </v-col>
        <v-col cols="9" class="px-0">
          {{item.distribution_name}}
        </v-col>
      </v-row>

      <v-row class="my-0 mx-4" style="font-size: 0.75rem; border-top:1px solid #E6E6E6">
        <v-col cols="3" class="px-0 font-weight-bold">
          有効期限
        </v-col>
        <v-col cols="9" class="px-0">
          {{item.disp_date}}
        </v-col>
      </v-row>

      <v-row class="my-0 mx-4" style="font-size: 0.75rem; border-top:1px solid #E6E6E6">
        <v-col cols="3" class="px-0 font-weight-bold">
          注意事項
        </v-col>
        <v-col cols="9" class="px-0">
          <p class="notes nl2br">{{item.attention}}</p>
          <p v-if="item.text_type" v-html="item.text_type" class="notes"></p>
        </v-col>
      </v-row>

      <div v-if="item.publish_start_date_flg == 0"><!-- 1の場合は、事前クーポンの表示となるので、ボタン類は表示しない -->
        <v-content v-if="item.usage_count_limit > 0">
          <div class="usage">
            残り <span class="maincolor big">{{item.usage_count_limit - item.usage_count}}</span><span class="maincolor bold">回／{{item.usage_count_limit}}回</span><br>使用できます
          </div>
          <v-divider class="vdbar2"></v-divider>
        </v-content>
        <v-content v-else-if="item.usage_count_limit === 1">
          <div class="usage">
            残り <span class="maincolor big">{{item.usage_count}}</span><span class="maincolor bold">回</span><br>使用できます
          </div>
        </v-content>
        <div class="text-center pt-1 pb-6" v-if="item.usage_count_limit > 0">
          <v-btn rounded class="maincolor-bg default_button" v-if="item.lottery_flg === 0 || item.scratch_flg === 1" @click.stop="openUseDialog(item)">クーポンを使用する</v-btn>
          <app-dialogue ref="ticketDialogue"></app-dialogue>
        </div>
        <div class="text-center pt-1 pb-6" v-if="item.lottery_flg === 1 && (item.scratch_flg != 1 || item.scratch_flg == null)">
          <v-btn rounded class="maincolor-bg default_button" @click.stop="lottery()">抽選をする</v-btn>
        </div>

        <scratchDialogue ref="scratchDialogue" :item="item" @getCoupon="closeWin"  @scratch="scratch"></scratchDialogue>
      </div>
      <div v-if="item.publish_start_date_flg == 1">
        <p class="text-center caption" style="color:red;">有効期限前のクーポンのため、<br>クーポンは使用できません。</p>
      </div>

      <!-- <div class="text-center pb-3">
        <v-btn rounded class="graybtn default_button" @click.stop="routerBack('coupon_list', false)">閉じる</v-btn>
      </div> -->
      <div class="text-center pt-10 pb-4 mx-16 px-5">
        <v-btn rounded depressed outlined small block color="#808080" style="" @click.stop="routerBack(prev_route)">閉じる</v-btn>
      </div>
    </v-card>

    <v-overlay class="mt-3" :value="used" absolute opacity="0.7" v-if="item.use_flg === 1">
      <div class="text-center">
        <img class="img" src="@/assets/check.svg" width="30">
        <p class="flgtext">チケット使用済み</p>
      </div>
      <div class="text-center pt-5">
        <v-btn rounded class="graybtn" width="100%" @click.stop="deleteCoupon(item.id)">削除する</v-btn>
      </div>
    </v-overlay>

    <appDialogue ref="appDialogue" @use="use" @pollingStop="pollingStop"></appDialogue>
    <ticketConfirmDialog ref="ticketConfirmDialog" @use="use"></ticketConfirmDialog>

    <v-overlay :value="loading">
      <v-progress-circular :size="80" :width="2" class="maincolor" indeterminate></v-progress-circular>
    </v-overlay>

  </div>
</template>

<script>
import ticketConfirmDialog from '../../components/ticket_confirm_dialog.vue'
import appDialogue from '../../components/ticket_dialogue.vue'
import scratchDialogue from "@/views/coupon/scratch_dialogue.vue"
export default {
    props: {
        item: {
            type: Object,
            required: true
        },
    },
    components: {
        appDialogue,
        scratchDialogue,
        ticketConfirmDialog
    },
    data() {
      return {
        loading: false,
        used: true,
        show: false,
        intervalId: undefined,
        prev_route: 'coupon_list'
      };
    },
    methods: {
        _removeTargetBlank(obj) {
          return this.removeTargetBlank(obj);
        },
        refToScracthOpen() {
            this.$refs.scratchDialogue.open()
        },
        refToParentOpen() {
            this.$refs.appDialogue.open()
        },
        closeWin() {
            this.$router.go({path: this.$router.currentRoute.path, force: true});
        },
        closeLose() {
            this.$router.push({ name: 'coupon_list', query: {}})
        },
        async use(code) {
          try {
            // 有効チェック
            this.couponCheck(this.item);
            //loadingを表示
            this.loading = true
            //QR認証を設定している場合はポーリングされているので、ポーリングを停止
            this.pollingStop()
            //念の為もう一度ローカルから取得（後ほど処理変える）
            this.init = this.storageGet('*')

            var params = {
              'sid': this.init.sid,
              'device_uid': this.init.device_uid,
              'device_id': this.init.device_id,
              'os': this.init.os,
              'key': this.init.key,
              'id': this.init.id,
              'code': code,
              'type': "3",
              'shop_id': String(this.$props.item.shop_id),
            }

            var res = await this.apiCall('/user/ticket/use', params, 'post', 0);
            if (!res) {
              this.loading = false;
              return false
            }

            this.$set(this, 'result', res);

            this.loading = false
            this.$router.go({path: this.$router.currentRoute.path, force: true});

          } catch(e) {
            this.loading = false
            this.callDialog('エラー', 'エラーが発生いたしました。', 3);
          }
        },
        async lottery() {
          try {
            //loadingを表示
            this.loading = true
            //念の為もう一度ローカルから取得（後ほど処理変える）
            this.init = this.storageGet('*')

            var params = {
              'sid': this.init.sid,
              'device_uid': this.init.device_uid,
              'device_id': this.init.device_id,
              'os': this.init.os,
              'key': this.init.key,
              'id': this.init.id,
            }

            var res = await this.apiCall('/user/coupon/lottery', params, 'post', 0);
            if (!res) {
              this.loading = false;
              return false
            } else {
              this.$refs.scratchDialogue.lottery(res.win_flg)
              this.$refs.scratchDialogue.open()
            }

            this.$set(this, 'result', res);

            this.loading = false


          } catch(e) {
            this.loading = false
            this.callDialog('エラー', 'エラーが発生いたしました。', 3);
          }
        },
        async scratch() {
          try {
            //loadingを表示
            this.loading = true
            //念の為もう一度ローカルから取得（後ほど処理変える）
            this.init = this.storageGet('*')

            var params = {
              'sid': this.init.sid,
              'device_uid': this.init.device_uid,
              'device_id': this.init.device_id,
              'os': this.init.os,
              'key': this.init.key,
              'id': this.init.id,
            }

            var res = await this.apiCall('/user/coupon/scratch', params, 'post', 0);
            if (!res) {
              this.loading = false;
              return false
            } else {
              this.loading = false
              this.$refs.scratchDialogue.showResult()
            }
          } catch(e) {
            this.loading = false
            this.callDialog('エラー', 'エラーが発生いたしました。', 3);
          }
        },
        async openUseDialog(item) {
          // 有効チェック
          this.couponCheck(item);

          if (item.auth_flg == 1) {

              var qr_url = "useTicket://?id=" + item.id + "&device_uid=" + this.init.device_uid + "&type=3" + "&os=" + this.init.os + "&device_id=" + this.init.device_id + "&sid=" + this.init.sid

              // ダイアログ表示
              this.$refs.appDialogue.open(item.id, item.auth_flg, qr_url);

              var count = 0
              var baseData = this.storageGet('*')
              var apiCall  = this.apiCall
              var router = this.$router
              var callDialog = this.callDialog
              var thisOne = this

              var useCheck = async function() {
              console.log("checking...")
                count++

                var params = { params:{
                  'sid': baseData.sid,
                  'service_cd': baseData.service_cd,
                  'device_uid': baseData.device_uid,
                  'device_id': baseData.device_id,
                  'os': baseData.os,
                  'key': baseData.key,
                  'id': baseData.id,
                }}

                try{
                  var res = await apiCall('/user/coupon', params, 'get');
                  if (res.usage_count != item.usage_count || res.use_flg == 1) {
                      thisOne.pollingStop()
                      router.go({path: router.currentRoute.path, force: true});
                  }
                  if (!res) return false;
                } catch(e) {
                    callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
                }
              }

              thisOne.intervalId = setInterval(function(){ //3秒ごとにapiを叩く
                  console.log('polling START');
                  useCheck();
                  if (count >= 20){ //1分後にポーリング終了
                      thisOne.pollingStop()
                  }
              }, 3000);

          } else {
              this.$refs.ticketConfirmDialog.open()
          }
        },

        async deleteCoupon(coupon_id) {
          try {
            //loadingを表示
            this.loading = true
            this.used = false
            //念の為もう一度ローカルから取得（後ほど処理変える）
            this.init = this.storageGet('*')

            var params = {
              'sid': this.init.sid,
              'device_uid': this.init.device_uid,
              'device_id': this.init.device_id,
              'os': this.init.os,
              'key': this.init.key,
              'id': coupon_id,
            }

            var res = await this.apiCall('/user/coupon/delete', params, 'post', 0);
            if (!res) {
              this.loading = false
              return false
            }

            this.$set(this, 'result', res);

              if (this.$router.history.router.beforeHooks.length == 0){
                this.$router.push({ name: 'coupon_list', query: {}})
              } else {
                this.$router.go(-1)
              }
          } catch(e) {
            this.loading = false
            this.callDialog('エラー', 'エラーが発生いたしました。', 3);
          }
        },
        pollingStop() {
          console.log('polling END');
          clearInterval(this.intervalId);
        },
        async couponCheck(item) {
          var params = { params:{
            'sid': this.init.sid,
            'service_cd': this.init.service_cd,
            'device_uid': this.init.device_uid,
            'device_id': this.init.device_id,
            'os': this.init.os,
            'key': this.init.key,
            'id': item.id,
          }}
          // クーポン取得
          var res = await this.apiCall('/user/coupon', params, 'get');
          console.log(res);
          if (!res || !res.id) {
            this.callDialog('エラー', 'エラーが発生いたしました。', 3);
          }
        },
    },
    created() {
      this.getUrlParam()
      this.init = this.storageGet('*')

      if (this.$route.query.prev_route) {
        this.prev_route = this.$route.query.prev_route;
      }
      // target="_blank"全削除
      // $('a[target="_blank"]').removeAttr('target')
      document.addEventListener('DOMContentLoaded', function(){
        Array.from(document.querySelectorAll('a[ ]')).forEach(link => link.removeAttribute('target'));
      });
    },
}
</script>

<style scoped>
/* vue css override */

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
    border-radius: 10px;
    margin-bottom: 40px;
}

/* original css */
@import url('https://fonts.googleapis.com/css?family=Oswald&display=swap');

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0 0 10px 10px !important;
  margin-bottom: 0 !important;
}
.coupon-detail {
  background-color: #E9D7B8;
  padding-top: 10px;
  box-shadow: none !important;
  border-radius: 10px !important;
}
.coupon_contents::v-deep p {
  margin-bottom: 0 !important;
}

.vdbar2 {
    width: 94%;
    margin: 0 auto 16px;
}

.chips {
    padding: 3px 10px;
    color: #fff;
    background-color: #EB762F;
    font-size: 10px;
    transform: scale(0.9);
    transform-origin: left center;
    border-radius: 20px;
}

.chips.birth {
	background-color: #F26474;
}

.coupon-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    color: #333;
    text-align: center;
    display: block;
    padding: 0.5rem 0 0;
}

.coupon_contents {
  line-height: 1.25rem;
  font-size: 0.75rem;
  color: #333;
  padding: 16px;
}

.flgtext {
    font-size: 16px;
    font-weight: bold;
    padding-top: 5px;
}

.usage {
    font-size: 16px;
    color: #333;
    line-height: 24px;
    text-align: center;
    padding-bottom: 10px;
}
.usage span.big {
    font-size: 40px;
    font-weight: bold;
    font-family: 'Oswald', sans-serif;
}
.bold {
    font-weight: bold;
}
/deep/ .coupon_contents p img {
  border-style: none;
  max-width: 100%;
}
</style>
